const themes = {
    default: {
      'background-color': '#fff',
      'font-family': 'Poppins',
      'font-weight':'regular',
      'name':'default',
      'color':'#000000'  
    },
    red: {
      'background-color': '#dc6868',
      'color': '#a50b0b',
      'font-family': "'Courier New', Courier, monospace"
    },
    dark: {
      'background-color': '#1a1a1a',
      'font-family': 'Poppins',
      'font-weight':'regular',
      'line-height':'1.4',
      'name':'dark',
      'color':'#ffffff'
    },
    white: {
      'background-color': '#fff',
      'color': '#847d7d',
      'font-family': 'Verdana, Geneva, Tahoma, sans-serif'
    }
  };
  
  export default themes;