import { useState, useCallback, useEffect, Suspense, lazy } from "react";
import { Provider } from "react-redux";
import store from "./redux/store/Store";
import themes from "../src/utils/themes";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Loader from "./components/ui/Spinner/index";
import "./App.css";
import 'aos/dist/aos.css';
import AOS from 'aos';
const LandingPage = lazy(() => import("./routes/LandingPage/components/index"));
const LunchApp = lazy(() => import("./routes/LaunchApp/components/index"));
function App() {
  AOS.init({
    duration: 1000,
    easing: 'ease-in-sine',
  });
  let [theme, setTheme] = useState(themes.dark);
  const [y, setY] = useState(window.scrollY);
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      setY(window.scrollY);
      if (y > 150) {
        if (
          document.getElementById("navbar") &&
          document.getElementById("navbar").classList
        ) {
          document.getElementById("navbar").classList.add("fixed-top");
        } else {
          if (document.getElementById("navbar")) {
            document.getElementById("navbar").classList.remove("fixed-top");
          }
        }
      }
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  const themeSwitcher = () => {
    if (theme.name === "default") {
      setTheme(themes.dark);
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    }
    if (theme.name === "dark") {
      setTheme(themes.default);
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay before setting loading to false
    const delay = setTimeout(() => {
      setLoading(false);
    }, 5000); // 2000 milliseconds delay (adjust as needed)

    return () => clearTimeout(delay); // Clean up timeout on unmount
  }, []);

  const refCallback = (node) => {
    if (node) {
      theme &&
        Object.keys(theme).forEach((element) => {
          node.style.setProperty(element, theme[element], "important");
          if (element === "background-color" || element === "background") {
            document.body.style.background = theme[element];
          }
        });
    }
  };
  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
        <div ref={refCallback}>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/launchapp" component={LunchApp} />
            {loading ? <Loader /> :
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <LandingPage
                      {...props}
                      themeSwitcher={themeSwitcher}
                      theme={theme && theme.name}
                    />
                  )}
                />
            }
          </Switch>
        </Suspense>
      </div>
       

        
        </Provider>
      </BrowserRouter>
    </>
  );
}

export default App;

// currenttimestamp=var ts = Math.round((new Date()).getTime() / 1000);
// lockAt-currenttimestamp = timeDifference.
// 5min - timeDifference = output.

//closePrice = last price bnb.

//lockprice-closedprice.
