import { useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import basinoLogo from '../../../assets/images/basino-logo.png';

const override = css`
  display: block;
  margin: 0 auto;
  border-color:#2876FB;
  border-width:5px;
  position:absolute;
  background:red;
  top:40%;
  transform:translate(0,-50%);
  left:0;
  right:0;
  margin:0 auto;
`;

const Loader = ({ value, size }) => {
  let [loading, setLoading] = useState(value);
  let [color, setColor] = useState("#750808f");

  return (
    <div>
      <div className="spinner-backdrop"></div>
      <div className="spinner-loader">
        <img src={basinoLogo} alt="Logo" class="spinner-logo"/>
        <div className="spinner-wrap"></div>
      </div>
      {/* <div className="sweet-loading">
      <ClipLoader color={color}  css={override} size={size ? size:150} />
      </div> */}
    </div>
  );
};
export default Loader;
