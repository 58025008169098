import {
  GET_ROUNDS_DATA,
  GET_ROUNDS_DATA_SUCCESS,
  GET_ROUNDS_DATA_ERROR,
} from "../index";
const initialState = {
  isLoading: false,
  roundsData: [],
  IsError: "",
};

const roundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROUNDS_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case  GET_ROUNDS_DATA_SUCCESS:
      return {
        isLoading: false,
        roundsData: action.payload,
        IsError: "",
      };

    case  GET_ROUNDS_DATA_ERROR:
      return {
        isLoading: false,
        usersData: [],
        IsError: action.payload,
      };
    default:
      return state;
  }
};
export default roundsReducer;
